import React from 'react'
import { bootstrapAppData } from '../lib/bootstrap'
import * as authService from '../services/auth.service'
import { useAsync } from '../hooks/useAsync'
import { FullPageSpinner } from '../components/atoms/full-page-spinner'
import { FullPageErrorMessage } from '../components/atoms/full-page-error-message'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync()

  React.useLayoutEffect(() => {
    run(bootstrapAppData())
  }, [run])

  const login = React.useCallback(
    form => authService.login(form).then(user => setData({ user })),
    [setData]
  )

  const register = React.useCallback(
    form => authService.register(form).then(user => setData({ user })),
    [setData]
  )
  const logout = React.useCallback(() => {
    authService.logout()
    setData(null)
  }, [setData])

  const user = data?.user

  const value = React.useMemo(
    () => ({ user, login, logout, register }),
    [login, logout, register, user]
  )

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorMessage title='error!' subtitle={error.message} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
