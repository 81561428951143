import { mode } from '@chakra-ui/theme-tools'

const Card = {
  // The styles all Cards have in common
  baseStyle: props => ({
    background: mode('white', 'whiteAlpha.200')(props),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 'lg',
    padding: 4,
  }),
  // Two variants: rounded and smooth
  variants: {
    'with-border': props => ({
      border: '2px',
      borderWidth: '2px',
      borderColor: 'transparent',
      transition: 'border-color 300ms',
      _hover: {
        borderColor: props.colorMode === 'light' ? 'black' : 'gray.300',
      },
    }),
    borderless: {
      border: 'none',
      borderColor: 'transparent',
    },
  },
  // The default variant value
  defaultProps: {
    variant: 'borderless',
  },
}

export { Card }
