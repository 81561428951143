import React from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import useWindowWidth from '../hooks/useWindowWidth'
import { customersReducer } from '../reducers/global.reducer'

const GlobalStoreContext = React.createContext()
const GlobalDispatchContext = React.createContext()
GlobalStoreContext.displayName = 'GlobalStoreContext'
GlobalStoreContext.displayName = 'GlobalDispatchContext'

export function GlobalProvider({ children }) {
  const windowWidth = useWindowWidth()
  const sidebarState = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  })

  const sidebarOpenBreakpoints = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  })

  const initialState = {
    isSidebarOpen: sidebarOpenBreakpoints,
    isSidebarFloating: sidebarState,
    screen_label: 'Dashboard',
    breadcrums: [],
  }

  const [store, dispatch] = React.useReducer(customersReducer, initialState)

  React.useEffect(() => {
    dispatch({ type: 'set-sidebar-floating', payload: sidebarState })
  }, [sidebarState])

  React.useEffect(() => {
    if (sidebarOpenBreakpoints) {
      dispatch({ type: 'sidebar-open' })
    } else {
      dispatch({ type: 'sidebar-closed' })
    }
    // window width not needed
  }, [sidebarOpenBreakpoints, windowWidth])

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStoreContext.Provider value={store}>
        {children}
      </GlobalStoreContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

export function useGlobalState() {
  return React.useContext(GlobalStoreContext)
}

export function useGlobalDispatch() {
  return React.useContext(GlobalDispatchContext)
}
