export const fonts = {
  heading: 'GT Walsheim, serif',
  body: 'GT Walsheim, serif',
  mono: 'JetBrainsMono, monospace',
}

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 600,
  black: 700,
}
