const FormLabel = {
  // The styles all Cards have in common
  baseStyle: {
    fontWeight: 'bold',
    fontSize: 'sm',
    textTransform: 'capitalize',
    lineHeight: 'shorter',
  },
  // The default variant value
  defaultProps: {
    marginBottom: 4,
  },
}

export { FormLabel }
