export default {
  white: '#FEFEFF',
  black: '#191B24',
  accent: {
    900: '#267062',
    800: '#318A79',
    700: '#3CA491',
    600: '#48BDA8',
    500: '#55D6BE',
    400: '#61E1C9',
    300: '#87F5E1',
    200: '#A2FDEC',
    100: '#CCFFF7',
    50: '#E4FFFC',
  },
  error: {
    900: '#7A2434',
    800: '#972E42',
    700: '#B43A51',
    600: '#CF4560',
    500: '#EA526F',
    400: '#F45D7A',
    300: '#FF7993',
    200: '#FF9CB1',
    100: '#FFD2DC',
    50: '#FFEBEF',
  },
  warning: {
    900: '#806925',
    800: '#9F8330',
    700: '#BF9C3C',
    600: '#DFB549',
    500: '#FDCD56',
    400: '#FFD660',
    300: '#FFE57A',
    200: '#FFF19C',
    100: '#FFF8BA',
    50: '#FFFEEA',
  },
  gray: {
    900: '#1F1F1F',
    800: '#373737',
    700: '#4F4F4F',
    600: '#676767',
    500: '#808080',
    400: '#B3B3B3',
    300: '#CBCBCB',
    200: '#E4E4E4',
    100: '#F0F0F0',
    50: '#F6F6F6',
  },
  primary: {
    900: '#292960',
    800: '#343478',
    700: '#3F3F8E',
    600: '#4C4CA4',
    500: '#5858BA',
    400: '#7474D2',
    300: '#9595E5',
    200: '#BBBBF3',
    100: '#DBDBFA',
    50: '#F1F1FE',
  },
  red: {
    900: '#7A2434',
    800: '#972E42',
    700: '#B43A51',
    600: '#CF4560',
    500: '#EA526F',
    400: '#F45D7A',
    300: '#FF7993',
    200: '#FF9CB1',
    100: '#FFD2DC',
    50: '#FFEBEF',
  },
  orange: {
    900: '#803223',
    800: '#9F402D',
    700: '#BF4E38',
    600: '#DD5D44',
    500: '#FA6C51',
    400: '#FF8268',
    300: '#FFA590',
    200: '#FFBEAE',
    100: '#FFD0C4',
    50: '#FFEEEA',
  },
  yellow: {
    900: '#806925',
    800: '#9F8330',
    700: '#BF9C3C',
    600: '#DFB549',
    500: '#FDCD56',
    400: '#FFD660',
    300: '#FFE57A',
    200: '#FFF19C',
    100: '#FFF8BA',
    50: '#FFFEEA',
  },
  green: {
    900: '#4F6D31',
    800: '#63873F',
    700: '#77A04D',
    600: '#8AB95B',
    500: '#9ED26A',
    400: '#AEE17B',
    300: '#BDEC8E',
    200: '#CDF5A5',
    100: '#DDFBBE',
    50: '#ECFFDA',
  },
  cyan: {
    900: '#267062',
    800: '#318A79',
    700: '#3CA491',
    600: '#48BDA8',
    500: '#55D6BE',
    400: '#61E1C9',
    300: '#87F5E1',
    200: '#A2FDEC',
    100: '#CCFFF7',
    50: '#E4FFFC',
  },
  blue: {
    900: '#226379',
    800: '#2C7A96',
    700: '#3792B2',
    600: '#43A9CD',
    500: '#4FC0E8',
    400: '#61CFF6',
    300: '#7FE1FF',
    200: '#9AEBFF',
    100: '#C5F7FF',
    50: '#DDFBFF',
  },
  navy: {
    900: '#2A4D7A',
    800: '#366197',
    700: '#4375B3',
    600: '#5088CF',
    500: '#5E9CEA',
    400: '#6EABF7',
    300: '#8ABFFF',
    200: '#ACD5FF',
    100: '#CAE5FF',
    50: '#ECF6FF',
  },
  purple: {
    900: '#424273',
    800: '#53538F',
    700: '#6565AA',
    600: '#7777C5',
    500: '#8A8AE0',
    400: '#9797EB',
    300: '#A6A6F4',
    200: '#BEBEFC',
    100: '#DADAFF',
    50: '#F2F2FF',
  },
  pink: {
    900: '#79405F',
    800: '#965077',
    700: '#B3628F',
    600: '#CF74A6',
    500: '#EB86BE',
    400: '#F593C9',
    300: '#FDAFDA',
    200: '#FFC2E5',
    100: '#FFE0F3',
    50: '#FFF1FA',
  },
  brown: {
    900: '#5B421C',
    800: '#715224',
    700: '#86622C',
    600: '#9B7235',
    500: '#AF823F',
    400: '#C49856',
    300: '#E0BB84',
    200: '#ECCFA3',
    100: '#F9E9D1',
    50: '#FDF5EB',
  },
}
