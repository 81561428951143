/* eslint-disable no-unused-vars */
import { client } from './api-client'

function handleUserResponse({ token, user }) {
  // window.localStorage.setItem(localStorageKey, token)
  return user
}

function getUser() {
  return client('v1/auth/me')
    .then(data => data.user)
    .catch(err => {
      return null
    })
}

function login({ email, password }) {
  return client('v1/auth/email/login', { data: { email, password } }).then(
    handleUserResponse
  )
}

function logout() {
  return client('v1/auth/logout').then(response => null)
}

// eslint-disable-next-line camelcase
function register({ first_name, last_name, email, password }) {
  return client('v1/auth/email/register', {
    data: { first_name, last_name, email, password },
  }).then(handleUserResponse)
}

export { login, logout, register, getUser }
