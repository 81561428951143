async function client(
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type': data ? 'application/json' : undefined,
      'x-app-id': process.env.REACT_APP_APP_ID,
      ...customHeaders,
    },
    credentials: 'include',
    ...customConfig,
  }

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then(
    async response => {
      const receivedData = await response.json()
      if (response.ok) {
        return receivedData
      }
      return Promise.reject(receivedData)
    }
  )
}

async function clientFormData(
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) {
  const config = {
    credentials: 'include',
    ...customConfig,
  }

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then(
    async response => {
      const json = await response.json()
      if (response.ok) {
        return json
      }
      return Promise.reject(json)
    }
  )
}

export { client, clientFormData }
