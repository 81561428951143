import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useToast } from '@chakra-ui/react'
import { AuthProvider } from './auth.context'
import { GlobalProvider } from './global.context'

function AppProviders({ children }) {
  const toast = useToast()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry(failureCount, error) {
          if (error.status === 404) return false
          if (failureCount < 2) return true
          return false
        },
        useErrorBoundary: false,
        refetchOnWindowFocus: true,
      },
      mutations: {
        onError: error => {
          if (error.message === 'Validation Error') {
            return error.errors.forEach(validationError =>
              toast({
                position: 'bottom-right',
                title: 'Hubo un error de validacion! 🤦‍♀️',
                description: `${validationError?.param}: ${validationError?.msg}`,
                status: 'error',
                duration: null,
                isClosable: true,
              })
            )
          }

          toast({
            position: 'bottom-right',
            title: 'Upps!! Algo salio mal',
            description: `${error.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <GlobalProvider>
          <AuthProvider>{children}</AuthProvider>
        </GlobalProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
    </QueryClientProvider>
  )
}

export { AppProviders }
