export function customersReducer(state, action) {
  switch (action.type) {
    case 'sidebar-open':
      return { ...state, isSidebarOpen: true }
    case 'sidebar-closed':
      return { ...state, isSidebarOpen: false }
    case 'sidebar-toggle':
      return { ...state, isSidebarOpen: !state.isSidebarOpen }
    case 'set-sidebar-floating':
      return { ...state, isSidebarFloating: action.payload }
    case 'set-breadcrums':
      return { ...state, breadcrums: [...action.payload] }
    case 'reset-breadcrums':
      return {
        ...state,
        breadcrums: [],
      }
    default:
      throw new Error(`Unhandled action type ${action.type}`)
  }
}
