import React from 'react'
import { Flex, Spinner, Heading, Text, useColorMode } from '@chakra-ui/react'

export function FullPageSpinner({
  height = '100vh',
  title,
  subtitle,
  ...rest
}) {
  const { colorMode } = useColorMode()

  return (
    <Flex
      w='full'
      height={height}
      justifyContent='center'
      alignItems='center'
      direction='column'
      {...rest}
    >
      <Spinner
        color='primary.500'
        emptyColor={colorMode === 'light' ? 'gray.100' : 'whiteAlpha.200'}
        size='xl'
        speed='0.65s'
        thickness='8px'
      />
      {title ? (
        <Heading fontSize='2xl' mt={8}>
          {title}
        </Heading>
      ) : null}
      {subtitle ? <Text mt={2}>{subtitle}</Text> : null}
    </Flex>
  )
}
