const FormErrorMessage = {
  parts: ['text', 'icon'],
  baseStyle: {
    text: {
      fontSize: 'xs',
    },
    icon: {
      fontWeight: 'medium',
      lineHeight: 'normal',
      color: 'gray.600',
    },
  },
  // sizes: {
  //   sm: {
  //     item: {
  //       fontSize: "0.75rem",
  //       px: 2,
  //       py: 1,
  //     },
  //   },
  //   md: {
  //     item: {
  //       fontSize: "0.875rem",
  //       px: 3,
  //       py: 2,
  //     },
  //   },
  // },
  // defaultProps: {
  //   size: "md",
  // },
}
export { FormErrorMessage }
