import { mode } from '@chakra-ui/theme-tools'

const styles = {
  global: props => ({
    html: {
      fontFamily: 'body',
      fontSize: '14px',
    },
    body: {
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('gray.50', 'gray.900')(props),
      transition: 'background-color 0.2s',
      lineHeight: 'base',
    },
  }),
}

export default styles
