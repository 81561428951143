import React from 'react'
import { Image, Flex, Heading, Text } from '@chakra-ui/react'
import url from '../../../assets/images/server_down.svg'

export function FullPageErrorMessage({
  height = '100vh',
  title,
  subtitle,
  message,
}) {
  return (
    <Flex
      w='full'
      height={height}
      justifyContent='center'
      alignItems='center'
      direction='column'
    >
      <Image src={url} w='50%' />
      {title ? (
        <Heading fontSize='3xl' mt={12}>
          {title}
        </Heading>
      ) : null}
      {subtitle ? (
        <Text fontSize='xl' mt={2}>
          {subtitle}
        </Text>
      ) : null}
      {message ? <Text mt={2}>{message}</Text> : null}
    </Flex>
  )
}
