import { extendTheme } from '@chakra-ui/react'

// global styles
import styles from './styles'
import colors from './foundations/colors'
import * as typography from './foundations/typography'
import shadows from './foundations/shadows'
import layerStyles from './foundations/layer'
import textStyles from './foundations/textStyles'
import { components } from './components'

const overrides = {
  styles,
  colors,
  fonts: typography.fonts,
  fontWeights: typography.fontWeights,
  shadows,
  components,
  layerStyles,
  textStyles,
}

export default extendTheme(overrides)
