import React from 'react'
import ReactDom from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import App from './app'
import { AppProviders } from './context'
import theme from './theme'
import 'moment/locale/es'
import './styles/type.css'

ReactDom.render(
  <ChakraProvider theme={theme}>
    <AppProviders>
      <App />
    </AppProviders>
  </ChakraProvider>,
  document.getElementById('root')
)
