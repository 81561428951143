// import { queryCache } from 'react-query'
import * as auth from '../services/auth.service'

async function bootstrapAppData() {
  let appData = { user: null }

  const response = await auth.getUser()

  appData = { user: response }
  return appData
}

export { bootstrapAppData }
