// This doesnt work
// const layerStyles = props => ({
//   base: {
//     bg: mode('white', 'gray.800')(props),
//     transition: 'background-color 0.2s',
//   },
// })

const layerStyles = {
  base: {
    bg: 'gray.50',
    transition: 'background-color 0.2s',
  },
}

export default layerStyles
